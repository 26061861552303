import React from "react";
import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
//import { AgGridReact } from "ag-grid-community/react";
//import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional Theme applied to the grid
//import "ag-grid-community/styles/ag-theme-material.min.css"; // Optional Theme applied to the grid
//import "ag-grid-community/styles/ag-theme-balham.min.css"; // Optional Theme applied to the grid
//import "ag-grid-community/styles/ag-theme-quartz.min.css"; // Optional Theme applied to the grid
import "./AgGrid.css"; // Mandatory CSS required by the grid
import { useMemo } from "react";
import { useCallback } from "react";
import { useRef } from "react";
import { useState } from "react";
import { Stack } from "@mui/material";
import {
  ButtonGroup,
  Button,
  Whisper,
  Popover,
  Dropdown,
  IconButton,
  Checkbox,
  CheckboxGroup,
  Message,
  useToaster,
} from "rsuite";
import PropTypes from "prop-types";
import ArrowDownIcon from "@rsuite/icons/ArrowDown";
import moment from "moment";
import {
  Check,
  ClearAll,
  Refresh,
  RestorePage,
  SaveAlt,
  Settings,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "app/hooks/useAxiosPrivate";
import NewButton from "./Button/Button";

export default function AgGrid({
  columns = [
    {
      headerName: "Athlete",
      field: "athlete",
      minWidth: 180,
    },
    { field: "age" },
    { field: "country", minWidth: 150 },
    { field: "year" },
    { field: "date", minWidth: 150 },
    { field: "sport", minWidth: 150 },
    { field: "gold" },
    { field: "silver" },
    { field: "bronze" },
    { field: "total", hide: true },
  ],
  rowData = [
    {
      athlete: "Michael Phelps",
      age: 23,
      country: "United States",
      year: 2008,
      date: "24/08/2008",
      sport: "Swimming",
      gold: 8,
      silver: 0,
      bronze: 0,
      total: 8,
    },
  ],
  onGridReady,
  ExportFileName = `${moment().format("DDMMYYYYHHmmss")}.csv`,
  defaultColumn = ["Name"],
  GoTo = "",
  showHeaderItems = true,
  passdata = false,
  QuickSearch = true,
  exportAllowed = true,
  ChooseColumns = true,
  showLoading = false,
  addMember = false,
  addDiscussion = false,
  handleOpen,
  onRowSelectedData,
  handleViewModalOpen,
  onDataChange,
  headerComponent,
  setFilteredRows,
  settingsName = "Test",
  Source = "Followup",
  theme = "ag-theme-alpine",
}) {
  const gridRef = useRef(null);
  const containerStyle = useMemo(
    () => ({ width: "100%", height: "600px" }),
    []
  );
  const gridStyle = useMemo(() => ({ height: "500px", width: "100%" }), []);
  /* const [rowData, setRowData] = useState(); */
  const [value, setValue] = React.useState(defaultColumn);
  const [searchText, setSearchText] = React.useState(null);
  const [columnDefs, setColumnDefs] = useState(columns);
  const [action, setAction] = React.useState(0);
  const navigate = useNavigate();
  const [settings, setSettings] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const [currentRowIndex, setCurrentRowIndex] = useState(0);
  const [filteredRowNodes, setFilteredRowNodes] = useState([]);
  const toaster = useToaster();
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      editable: false,
      filter: true,
      initialWidth: 150,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      enableCellChangeFlash: true,
      headerComponentParams: {
        menuIcon: "fa-bars",
      },
    };
  }, []);
  const gridOptions = useMemo(() => {
    // callback tells the grid to use the 'id' attribute for IDs, IDs should always be strings
    return {
      getRowId: (params) => params.data.UniqueID,
      defaultColDef: {
        flex: 1,
        editable: false,
        filter: true,
        minWidth: 150,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        enableCellChangeFlash: true,
        headerComponentParams: {
          menuIcon: "fa-bars",
        },
      },
      enableCellTextSelection: true, // Enable text selection for copying
      enableBrowserTooltips: true, // Enables native browser tooltips
    };

    // other grid options ...
  }, []);
  const updateFilteredRowNodes = useCallback(() => {
    const api = gridRef.current.api;
    if (api) {
      const filteredNodes = [];
      api.forEachNodeAfterFilterAndSort((node) => {
        if (node.group) return;
        filteredNodes.push(node);
      });
      setFilteredRowNodes(filteredNodes);
      setFilteredRows(filteredNodes);
      // console.log(filteredNodes);
      setCurrentRowIndex(filteredNodes.length > 0 ? 0 : -1);
    }
  }, []);

  React.useEffect(() => {
    if (gridRef.current && rowData.length > 0) {
      updateFilteredRowNodes();
    }
  }, [rowData, updateFilteredRowNodes]);

  React.useEffect(() => {
    if (gridRef.current && filteredRowNodes.length > 0) {
      const currentNode = filteredRowNodes[currentRowIndex];
      gridRef.current.api.ensureIndexVisible(currentNode.rowIndex);
      currentNode.setSelected(true);
    }
  }, [currentRowIndex, filteredRowNodes]);

  React.useEffect(() => {
    if (gridRef)
      if (gridRef.current) {
        if (showLoading) {
          gridRef.current.api.showLoadingOverlay();
        } else {
          //gridRef.current.api.hideOverlay();
        }
      }
  }, [showLoading]);
  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      let abc = columns.map((a) => {
        return !a?.hide && a.field;
      });
      setValue(abc);
    }
    return () => {
      isSubscribed = false;
    };
  }, []);

  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      const newdata = columns.filter((abc) => {
        return value.includes(abc.field);
      });
      setColumnDefs(newdata);
    }

    return () => {
      isSubscribed = false;
    };
  }, [value.length]);

  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      axiosPrivate
        .post("GetSettings.php", { Source })
        .then((responce) => {
          const result = responce.data;
          if (result.status === 1) {
            setValue(result.Columns);

            const newdata = columns.filter((abc) => {
              return result.Columns.includes(abc.field);
            });
            setColumnDefs(newdata);
          } else {
            setSettings([{}]);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }

    return () => {
      isSubscribed = false;
    };
  }, []);

  const onQuickFilterChanged = useCallback(
    (e) => {
      gridRef.current.api.setGridOption("quickFilterText", e.target.value);
    },
    [searchText]
  );
  //event.colDef.field === "Status" ||
  const onRowSelected = useCallback(
    (event) => {
      if (GoTo && event.colDef.field === "ID") {
        if (event.node.isSelected())
          if (passdata) navigate(`${GoTo}`, { state: event.node.data });
          else if (Source === "Followup")
            navigate(`${GoTo}/${event.node.data?.ProspectID}`);
          else if (Source === "FollowupMenu")
            if (event.node.data?.Type === "CLIAAgencyData")
              navigate(
                `/CLIAProspect/EditCLIAProspect/${event.node.data?.ProspectID}`
              );
            else
              navigate(
                `/Prospects/ViewProspect/${event.node.data?.ProspectID}`
              );
          else navigate(`${GoTo}/${event.node.data?.UniqueID}`);
      } else if (event.colDef.field === "Result") {
        handleViewModalOpen();
      } else if (event.colDef.field === "Name" && Source === "Family") {
        handleViewModalOpen();
      } else if (Source === "PremiumPoint") {
        if (
          event.colDef.field === "Reference" &&
          event.node.data?.ReferenceID !== null
        ) {
          navigate(`/Prospects/ViewProspect/${event.node.data?.ReferenceID}`);
        } else handleViewModalOpen();
      } else if (
        Source === "CurrentMonthDue" ||
        Source === "OutstandingDueList" ||
        Source === "LICMaturityList" ||
        Source === "SBPolicyList" ||
        Source === "PastePremiumPoint" ||
        Source === "LapsedPolicyDue"
      ) {
        handleViewModalOpen();
      } else if (event.colDef.field === "ID" && Source === "Followup") {
        navigate(`${GoTo}/${event.node.data?.ProspectID}`);
        console.log("Column:", event.node);
      } else if (
        GoTo &&
        event.colDef.field === "FirstName" &&
        Source === "User"
      ) {
        if (event.node.isSelected())
          navigate(`${GoTo}/${event.node.data?.UniqueID}`);
      } else if (
        GoTo &&
        event.colDef.field === "fullname" &&
        Source === "DahiyaMitra"
      ) {
        if (event.node.isSelected())
          navigate(`${GoTo}/${event.node.data?.UniqueID}`);
      } else if (
        GoTo &&
        event.colDef.field === "fullname" &&
        Source === "CLIA"
      ) {
        if (event.node.isSelected())
          navigate(`${GoTo}/${event.node.data?.UniqueID}`);
      } else if (GoTo && Source === "Policy") {
        if (event.node.isSelected()) {
          if (event.colDef.field === "RefID")
            navigate(`/Prospects/ViewProspect/${event.node.data?.ReferenceID}`);
          if (event.colDef.field === "PolicyNo")
            navigate(`${GoTo}/${event.node.data?.UniqueID}`);
        }
      } else if (
        GoTo &&
        (Source === "CLIAReference" ||
          Source === "CLIAProspect" ||
          Source === "CLIAAgencyData")
      ) {
        if (event.node.isSelected()) {
          if (event.colDef.field === "RefID")
            navigate(`${GoTo}/${event.node.data?.UniqueID}`);
        }
      } else if (
        GoTo &&
        (Source === "Bond" ||
          Source === "ENach" ||
          Source === "MinorDAB" ||
          Source === "MinorNomination")
      ) {
        if (event.node.isSelected()) {
          if (event.colDef.field === "RefID")
            navigate(`/Prospects/ViewProspect/${event.node.data?.ReferenceID}`);
          if (event.colDef.field === "PolicyNo")
            navigate(`${GoTo}/${event.node.data?.UniqueID}`);
        }
      } else if (GoTo && Source === "PolicyService") {
        if (event.node.isSelected()) {
          if (event.colDef.field === "Reference")
            navigate(`/Prospects/ViewProspect/${event.node.data?.ReferenceID}`);
          if (event.colDef.field === "PolicyNo")
            if (
              event.node.data?.PolicyNoID !==
              "00000000-0000-0000-0000-000000000000"
            )
              navigate(`/Policy/View/${event.node.data?.PolicyNoID}`);
          if (event.colDef.field === "ServiceID")
            navigate(`${GoTo}/${event.node.data?.UniqueID}`);
        }
      }
      onRowSelectedData(event.node.data);
      console.log(event.node.data);
      /*            console.log("Cell clicked:", event);
      console.log("Row index:", event.rowIndex); */
    },
    [window]
  );

  const autoSizeStrategy = useMemo(
    () => ({
      type: "fitGridWidth",
    }),
    []
  );
  /*   const getRowId = useCallback((params) => params.data?.UniqueID, []);
  const onSelectionChanged = useCallback(
    (event) => {
      var rowCount = event.api.getSelectedNodes().length;
      window.alert("selection changed, " + rowCount + " rows selected");
    },
    [window]
  ); */
  const resetFilters = () => {
    gridRef.current.api.setFilterModel(null); // Reset all filters
    setSearchText(null);
  };
  const onCellValueChanged = async (event) => {
    //console.log(event);
    if (
      (event.colDef.field === "Status" ||
        event.colDef.field === "LeadStatus") &&
      Source === "Prospect"
    ) {
      const datatosend = {
        UID: event.data.UniqueID,
        Status: event.data.Status,
        LeadStatus: event.data.LeadStatus,
        Type: Source,
        Data: "Status",
      };
      handleInputChange(datatosend);
    }

    if (event.colDef.field === "LeadStatus" && Source === "Followup") {
      const datatosend = {
        UID: event.data.ProspectID,
        LeadStatus: event.data.LeadStatus,
        Type: Source,
        Data: "LeadStatus",
      };
      handleInputChange(datatosend);
    }
    // Send the updated cell value to the backend server
    /* try {
      await axios.post("/path/to/your/api/update", datatosend);
      console.log("Cell value updated successfully.");
    } catch (error) {
      console.error("Error updating cell value:", error);
    } */
  };

  async function SaveSettings() {
    if (gridRef.current) {
      const columnState = gridRef.current.columnApi.getColumnState();
      window.colState = gridRef.current.columnApi.getColumnState();
      //const sortState = gridRef.current.api.getSortModel();
      const settings = JSON.stringify({ columnState });
      try {
        const responce = await axiosPrivate.post("SaveSettings.php", {
          Columns: value,
          Source: `${Source}`,
          Settings: settings,
        });
        const result = responce.data;
        //console.log(result);
        if (result.status === 1) {
          toaster.push(
            <Message type="success" bordered showIcon>
              <strong>Success!</strong> {result.Success}
            </Message>
          );
        } else {
          toaster.push(
            <Message type="error" bordered showIcon>
              <strong>Error!</strong> Error saving settings.
            </Message>
          );
        }
      } catch (e) {
        console.error("Error saving settings:", e);
      }
    }
  }
  async function deleteSettings() {
    try {
      const responce = await axiosPrivate.post("DeleteSettings.php", {
        Source: `${Source}`,
        Settings: settings,
      });
      const result = responce.data;
      //console.log(result);
      if (result.status === 1) {
        toaster.push(
          <Message type="success" bordered showIcon>
            <strong>Success!</strong> {result.Success}
          </Message>
        );
      } else {
        toaster.push(
          <Message type="error" bordered showIcon>
            <strong>Error!</strong> Error saving settings.
          </Message>
        );
      }
    } catch (e) {
      console.error("Error saving settings:", e);
    }
  }
  function resetState() {
    // console.log("column state reset");
    if (gridRef)
      if (gridRef.current) {
        //        gridRef.current.columnApi.setColumnState(window.colState);
      }
  }

  const applySettings = (settings, result) => {
    if (gridRef.current && gridRef.current.columnApi && settings.length > 0) {
      const newColumnDefs = settings
        .map((field, _) => {
          const colDef = columns.find((colDef) => colDef.field === field.colId);

          return { ...colDef, width: field.width };
        })
        .filter(Boolean);
      // console.log(newColumnDefs);
      const newdata = newColumnDefs.filter((abc) => {
        return result.includes(abc.field);
      });
      // console.log(newdata);
      gridRef.current.api.applyColumnState({
        state: newdata,
        applyOrder: true,
      });
      setColumnDefs(newdata);
    } else {
      console.log("No Effect");
    }
  };

  function getdata(params) {
    axiosPrivate
      .post("GetSettings.php", { Source })
      .then((responce) => {
        const result = responce.data;
        if (result.status === 1) {
          setSettings(result.Settings);
          setValue(result.Columns);
          const { columnState } = result.Settings;
          applySettings(columnState, result.Columns);
          onGridReady(params);
        }
        if (result.status === 0) onGridReady(params);
      })
      .catch((e) => {
        console.log(e);
        onGridReady(params);
      });
  }

  const handleInputChange = (value) => {
    onDataChange(value);
  };

  const onBtnExport = useCallback(() => {
    gridRef.current.api.exportDataAsCsv({
      fileName: `${Source}-${ExportFileName}`,
    });
  }, []);

  return (
    <div style={containerStyle}>
      <div className="example-wrapper">
        {showHeaderItems && (
          <div
            style={{
              margin: "5px",
              flexDirection: "row",
              justifyContent: "space-between",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Stack direction="row" spacing={1}>
              {QuickSearch && (
                <input
                  type="text"
                  onInput={onQuickFilterChanged}
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  id="quickFilter"
                  placeholder="Quick Search/Filter..."
                />
              )}
              {exportAllowed && (
                <NewButton action={onBtnExport} title="Download CSV" />
              )}
              {ChooseColumns && (
                <ButtonGroup>
                  <Button appearance="primary">Columns</Button>
                  <Whisper
                    style={{}}
                    placement="bottomEnd"
                    trigger="click"
                    speaker={({ onClose, left, top, className }, ref) => {
                      const handleSelect = (eventKey) => {
                        onClose();
                        setAction(eventKey);
                        //console.log(eventKey);
                      };
                      return (
                        <Popover
                          ref={ref}
                          className={className}
                          style={{
                            left,
                            top,
                            maxHeight: 500,
                            overflowY: "scroll",
                          }}
                          full
                        >
                          <Dropdown.Menu onSelect={handleSelect}>
                            <CheckboxGroup
                              name="checkbox-group"
                              style={{ padding: "0px 10px 0px 10px" }}
                              value={value}
                              onChange={(value) => {
                                setValue(value);
                              }}
                            >
                              {columns.map((item, index) => (
                                <Checkbox
                                  value={item.field}
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {item.headerName || item.field}
                                </Checkbox>
                              ))}
                            </CheckboxGroup>
                          </Dropdown.Menu>
                        </Popover>
                      );
                    }}
                  >
                    <IconButton appearance="primary" icon={<ArrowDownIcon />} />
                  </Whisper>
                </ButtonGroup>
              )}
              {addMember && (
                <Button onClick={handleOpen} appearance="link">
                  Add Member
                </Button>
              )}
              {addDiscussion && (
                <Button onClick={handleOpen} appearance="link">
                  Add Discussion
                </Button>
              )}
              <Button
                appearance="link"
                startIcon={<Settings />}
                onClick={SaveSettings}
              >
                Save
              </Button>
              <Button
                appearance="link"
                startIcon={<Settings />}
                onClick={deleteSettings}
              >
                Reset
              </Button>
              {/* <Button
                appearance="link"
                startIcon={<Settings />}
                onClick={getdata}
              >
                Reload
              </Button> */}
              <Button
                appearance="link"
                startIcon={<ClearAll />}
                onClick={resetFilters}
              >
                Clear All
              </Button>
            </Stack>
            {headerComponent()}
            <Stack direction="row" spacing={1}>
              <IconButton
                onClick={onGridReady}
                loading={showLoading}
                appearance="primary"
                active
                icon={<Refresh />}
                size="sm"
              />
            </Stack>
          </div>
        )}

        <div style={gridStyle} className={theme}>
          <AgGridReact
            overlayLoadingTemplate={
              '<span class="ag-overlay-loading-center">Loading...</span>'
            }
            overlayNoRowsTemplate={
              '<span class="ag-overlay-loading-center">No data to show</span>'
            }
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            onCellValueChanged={onCellValueChanged}
            onGridReady={getdata}
            rowSelection="single"
            pagination={true}
            autoSizeStrategy={autoSizeStrategy}
            onColumnMoved={(a) => {
              // console.log(a.api.getColumnState());
              setSettings(a.api.getColumnState());
            }}
            onRowSelectedData={onRowSelectedData}
            //handleViewModalOpen={handleViewModalOpen}
            onCellClicked={onRowSelected}
            onFilterChanged={updateFilteredRowNodes}
            gridOptions={gridOptions}
          />
        </div>
      </div>
    </div>
  );
}
// Define the prop types and set a default prop
AgGrid.propTypes = {
  onRowSelectedData: PropTypes.func,
  handleViewModalOpen: PropTypes.func,
  onDataChange: PropTypes.func,
  headerComponent: PropTypes.func,
  setFilteredRows: PropTypes.func,
};

AgGrid.defaultProps = {
  // Default function that does nothing
  onRowSelectedData: () => {},
  handleViewModalOpen: () => {},
  onDataChange: () => {},
  headerComponent: () => {},
  setFilteredRows: () => {},
};
