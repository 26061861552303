import * as React from "react";
import AgGrid from "app/components/AgGrid";
import useAxiosPrivate from "app/hooks/useAxiosPrivate";
import {
  Tabs,
  Panel,
  Divider,
  Grid,
  Row,
  Col,
  Message,
  useToaster,
  Heading,
  Stack,
} from "rsuite";
import { useLocation, useNavigate } from "react-router-dom";
import SideDrawer from "app/components/SideDrawer";
import { LoadingButton } from "@mui/lab";
import { Formik } from "formik";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useTheme,
} from "@mui/material";
import * as yup from "yup";
import { useProps } from "./ReferenceContext";
import { Status } from "app/DataSource";
import { filterParams } from "functions";
import useAPINew from "app/hooks/useAPINew";

const validationSchema = yup.object({
  Status: yup.string().required("Status is required"),
});
function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
export default function ListReference() {
  const axiosPrivate = useAxiosPrivate();
  const toaster = useToaster();
  const [agencyStatus, setAgencyStatus] = React.useState("ALL");
  const [oldStatus, setOldStatus] = React.useState("");
  const [rowData, setRowData] = React.useState([{}]);
  const [rowColdData, setRowColdData] = React.useState([{}]);
  const StatusList = useAPINew("GetReferenceStatusList.php");
  const [rowWarmData, setWarmRowData] = React.useState([{}]);
  const [rowHotData, setHotRowData] = React.useState([{}]);
  const [rowStoreData, setStoreRowData] = React.useState([{}]);
  const history = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = React.useState(false);
  const [activeKey, setActiveKey] = React.useState("ALL");
  const [openViewModal, setOpenViewModal] = React.useState(false);
  const handleViewModalOpen = () => setOpenViewModal(true);
  const handleViewModalClose = () => setOpenViewModal(false);
  const [selectedRowData, setSelectedRowData] = React.useState(null);
  const [filteredRows, setFilteredRows] = React.useState([]);
  const [initialValues, setInitialValues] = React.useState({
    Status: null,
  });
  const { updatePropsValue } = useProps();

  const handleUpdateMessage = () => {
    const NewData = filteredRows.map((dt) => dt.data.UniqueID);
    updatePropsValue(NewData);
    //console.log(propsValue);
  };
  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      handleUpdateMessage();
      //console.log(filteredRows);
    }
    return () => {
      isSubscribed = false;
    };
  }, [filteredRows.length]);
  const theme = useTheme();

  const [childData, setChildData] = React.useState({});
  const UpdateStatus = (data) => {
    axiosPrivate
      .post("UpdateStatusReference.php", data)
      .then((response) => {
        const result = response.data;
        if (result.status === 1) {
          console.log(result);
          toaster.push(
            <Message type="success" bordered showIcon>
              <strong>Success!</strong> {result?.Success}
            </Message>
          );
        }
        if (result.status === 0) {
          toaster.push(
            <Message type="error" bordered showIcon>
              <strong>Error!</strong> {result.Error}
            </Message>
          );
        }
      })
      .catch((error) => {
        console.error("Error submitting data:", error);
      });
  };

  const handleChildData = (data) => {
    setChildData(data);
    UpdateStatus(data);
  };
  React.useEffect(() => {
    // console.log(selectedRowData);
    let isSubscribed = true;
    if (isSubscribed)
      if (selectedRowData?.Name) {
        setInitialValues({
          UID: selectedRowData?.UniqueID,
          Status: selectedRowData?.Status,
        });
        setOldStatus(selectedRowData?.Status);
      }
    return () => {
      isSubscribed = false;
    };
  }, [selectedRowData?.Name]);

  // const abc = useReference();
  // console.log(abc);
  const ColorStatus = React.useMemo(() => {
    return ["#FC6E51", "#FFCE54", "#A0D468", "#4FC1E9"];
  }, []);
  const columns = React.useMemo(() => {
    return [
      {
        headerName: "Ref ID",
        field: "ID",
        minWidth: 180,
        cellStyle: {
          color: "blue",
          textDecoration: "underline",
          cursor: "pointer",
        },
      },
      {
        field: "Date",
        minWidth: 150,
        type: "date",
        filter: "agDateColumnFilter",
        editable: false,
        filterParams: filterParams,
        headerName: "First Visit Date",
      },
      {
        field: "Place",
        minWidth: 150,
        editable: false,
        headerName: "First Visit Place",
      },
      {
        field: "Status",
        minWidth: 150,
        editable: true,
        cellStyle: (params) => {
          if (params.value === "HOT" || params.value === "Hot") {
            //mark police cells as red
            return { color: ColorStatus[0], fontWeight: "bold" };
          } else if (params.value === "WARM" || params.value === "Warm") {
            //mark police cells as red
            return { color: ColorStatus[1], fontWeight: "bold" };
          } else if (params.value === "COLD" || params.value === "Cold") {
            //mark police cells as red
            return { color: ColorStatus[2], fontWeight: "bold" };
          } else {
            //mark police cells as red
            return { color: ColorStatus[3] };
          }
        },
      },
      {
        field: "RefByName",
        minWidth: 150,
        editable: false,
        headerName: "Ref By",
      },
      { field: "Gender", minWidth: 150, editable: false },
      {
        field: "DOB",
        minWidth: 150,
        type: "date",
        filter: "agDateColumnFilter",
        filterParams: filterParams,
        editable: false,
      },
      {
        headerName: "Name",
        field: "Full_Name",
        minWidth: 180,
        editable: false,
      },
      {
        headerName: "Age",
        field: "Age",
        minWidth: 180,
        editable: false,
      },
      { field: "MaritalStatus", minWidth: 100 },
      { field: "Caste", minWidth: 150, editable: false },
      { field: "Category", minWidth: 150 },
      { field: "Purpose", minWidth: 150 },
      { field: "Address", minWidth: 150, editable: false },
      { field: "Village", minWidth: 150, editable: false },
      { field: "PIN", minWidth: 150, type: "numericColumn", editable: false },
      { field: "Tehsil", minWidth: 150, editable: false },
      { field: "District", minWidth: 150, editable: false },
      { field: "State", minWidth: 150, editable: false },
      {
        field: "WhatsAppNo",
        minWidth: 150,
        type: "numericColumn",
        editable: false,
      },
      {
        field: "MobileNo",
        minWidth: 150,
        type: "numericColumn",
        editable: false,
        headerName: "Resi Mob No",
      },
      { field: "Email", minWidth: 150, headerName: "Email" },
      { field: "Profession", minWidth: 150, editable: false },
      { field: "WorkType", minWidth: 150, editable: false },
      {
        field: "Other",
        minWidth: 150,
        headerName: "Other Details",
        editable: false,
      },
      { field: "AddedBy", minWidth: 150, editable: false },
      {
        field: "AddedOn",
        minWidth: 150,
        editable: false,
        type: "date",
        filter: "agDateColumnFilter",
        filterParams: filterParams,
      },
      {
        field: "AssignedToName",
        headerName: "AssignedTo",
        minWidth: 150,
        editable: false,
      },
      { field: "UniqueID", hide: true, editable: false },
    ];
  }, []);

  const onGridReady = React.useCallback(
    (params) => {
      setLoading(true);
      axiosPrivate
        .post("LoadReferencesByFilter.php", { Status: agencyStatus })
        .then((responce) => {
          const result = responce.data;
          if (result.status === 1) setRowData(result.Data);
          else setRowData([]);
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
    },
    [agencyStatus]
  );

  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) onGridReady();
    return () => {
      isSubscribed = false;
    };
  }, [agencyStatus]);

  return (
    <>
      <div
        style={{ width: "100%", border: "0.5px dashed #999", borderRadius: 10 }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Stack
            direction="row"
            spacing={4}
            style={{ flex: 0.3, justifyContent: "flex-start", padding: 5 }}
          >
            <FormControl variant="standard" sx={{ minWidth: 120 }}>
              <InputLabel id="demo-simple-select-standard-label">
                Status
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                label="Agency Status"
                name="AgencyStatus"
                value={agencyStatus || ""}
                onChange={(e) => {
                  setAgencyStatus(e.target.value);
                  //console.log("Status=> " + e.target.value);
                }}
                onSelect={(e) => {
                  setAgencyStatus(e.target.value);
                  //console.log("Status=> " + e.target.value);
                }}
              >
                {[...StatusList.data, "ALL"].map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={getStyles(name, [...StatusList.data, "ALL"], theme)}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <Heading
            level={5}
            style={{
              textAlign: "center",
              flex: 0.5,
            }}
          >
            {`${agencyStatus} Reference Data`}
          </Heading>
          <Stack
            direction="row"
            spacing={2}
            style={{ flex: 0.2, justifyContent: "flex-end" }}
          >
            {/* <Button
              disabled={!permissions.includes(10000)}
              onClick={clearAll}
              appearance="link"
              startIcon={<DeleteForever />}
            >
              Clear All
            </Button> */}
          </Stack>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          maxHeight: 600,
          border: "0.5px dotted #999",
          marginTop: 5,
          borderRadius: 10,
          overflowY: "auto",
        }}
      >
        <AgGrid
          columns={columns}
          rowData={rowData}
          onGridReady={onGridReady}
          defaultColumn={["Name"]}
          GoTo="/References/ViewReference"
          showLoading={loading}
          handleViewModalOpen={handleViewModalOpen}
          onRowSelectedData={setSelectedRowData}
          settingsName="Reference"
          onDataChange={handleChildData}
          headerComponent={() => <p></p>}
          setFilteredRows={setFilteredRows}
          Source="Reference"
        />
      </div>
      <SideDrawer
        open={openViewModal}
        onClose={handleViewModalClose}
        title="Change status"
      >
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            console.log(values);
            axiosPrivate
              .post("UpdateStatusReference.php", {
                UID: values.UID,
                Status: values.Status,
              })
              .then((response) => {
                const result = response.data;
                if (result.status === 1) {
                  console.log(result);
                  toaster.push(
                    <Message type="success" bordered showIcon>
                      <strong>Success!</strong> {result?.Success}
                    </Message>
                  );
                }
                if (result.status === 0) {
                  toaster.push(
                    <Message type="error" bordered showIcon>
                      <strong>Error!</strong> {result.Error}
                    </Message>
                  );
                }
                setSubmitting(false);
                resetForm();
              })
              .catch((error) => {
                console.error("Error submitting data:", error);
                setSubmitting(false);
              });
            console.log(values);
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            handleSubmit,
            isValidating,
            isSubmitting,
            touched,
            setFieldValue,
            handleBlur,
            handleChange,
          }) => {
            console.log(errors);
            console.log(values);
            return (
              <form>
                <div>
                  <div className="row">
                    <Grid fluid style={{ padding: 4 }}>
                      <Row className="show-grid">
                        <Col xs={24} sm={24} md={24}>
                          <Row gutter={0} style={{ marginBottom: 10 }}>
                            <FormControl
                              variant="standard"
                              sx={{ minWidth: 120 }}
                            >
                              <InputLabel id="demo-simple-select-standard-label">
                                Status
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                label="Status"
                                name="Status"
                                value={values?.Status || ""}
                                onChange={handleChange}
                                error={touched.Status && Boolean(errors.Status)}
                                helperText={touched.Status && errors.Status}
                              >
                                {Status.map((name) => (
                                  <MenuItem
                                    key={name}
                                    value={name}
                                    style={getStyles(name, Status, theme)}
                                  >
                                    {name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Row>
                        </Col>
                      </Row>
                    </Grid>
                  </div>
                  &nbsp; &nbsp;
                  <>
                    <LoadingButton
                      disabled={isSubmitting}
                      type="submit"
                      color="secondary"
                      variant="contained"
                      onClick={(e) => {
                        e.preventDefault();
                        handleSubmit(e);
                      }}
                      loading={isValidating}
                      sx={{ my: 2 }}
                    >
                      {isSubmitting ? "Submitting..." : "Submit"}
                    </LoadingButton>
                  </>
                </div>
              </form>
            );
          }}
        </Formik>
      </SideDrawer>
    </>
  );
}
